import inquiryModel from "../components/inquiryModel.vue"   //生成询价弹框
import quoteModel from "../components/quoteModel.vue"
import { createLidaButton, imgUrl } from "@/common/js/common.js"
import { ElMessage } from 'element-plus'
import { mapState, mapActions, mapMutations } from 'vuex'
import shareLinkDialog from '../components/dialog/shareLink.vue'  //询价成功生成链接弹框
/* 公共的table操作 */
export default {
	data() {
		return {
			inquiryShow: false,  //询价弹框的隐藏显示
			qutoeShow: false, // 报价弹窗
			cartGoods: [],  //加入购物车的商品
			quoteGoodsList: [], // 加入报价单的商品
			shareLinkShow: false,  //分享弹框的隐藏显示
			inquiryId: null,  //生成询价单成功后的询价单id
		}
	},
	components: {
		inquiryModel,
		shareLinkDialog,
		quoteModel
	},
	methods: {
		...mapActions('purchaseMudule',
			[
				'selectionList',  //获取购物车列表
				'selectionCreate',  //添加商品
				'deleteSelection',  //移除商品
			]),
		getSummaries(param) {
			const { columns, data } = param;
			this.$nextTick(() => {
				try {
					var doms = this.$refs.table.$refs.lidaTable.$refs.footerWrapper.querySelector('.el-table__footer').querySelectorAll('td');
					if (doms[3].querySelector('.cell').childNodes.length <= 1) {
						doms[3].querySelector('.cell').appendChild(this.instance.$el);
						doms[3].querySelector('.cell').appendChild(this.quoteBtnInstance.$el);
					}
					doms[2].querySelector('.cell').innerHTML = `总价：<span class="lida-total-price">￥${this.getprice()}<span>`;
				} catch (e) {
					//TODO handle the exception
				}
			})
			return [
				"合计",
				`已选择${this.goodsList.filter(v => v.active).length}件商品`,
			];
		},
		/* selectGoods(data){
			var goodsList = JSON.parse(JSON.stringify(this.goodsList));
			goodsList.map(item => {
				item.active = data.some(v => v.id === item.id);
			})
			this.goodsList = [...goodsList];
		}, */
		selectGoods(data) { //选中商品
			this.goodsList.map(item => {
				item.active = data.some(v => v.id === item.id);
			})
			this.$forceUpdate();
		},
		getprice() { //计算选中商品总价
			var arr = this.goodsList.filter(v => v.active),
				totalPrice = 0;
			arr.map(item => {
				totalPrice += parseFloat(item.guidePrice) * parseFloat(item.num);
			})
			return totalPrice.toFixed(2);
		},
		addCart() { //加入购物车
			this.addData('INQUIRY')
		},

		addQuoteList() { // 加入报价单
			this.addData('QUOTE')
		},

		addData(type, list) {
			let arr;
			// list 是替换商品 直接加购物车 报价单
			if (list) {
				arr = JSON.parse(JSON.stringify(list));
			} else {
				arr = this.goodsList.filter(v => v.active);
				arr = JSON.parse(JSON.stringify(arr));
			}
			if (arr.length) {
				var data = [];
				arr.map(item => {
					data.push({
						skuId: item.id,  //商品id
						skuQty: item.num,
						type,
					})
				})
				this.selectionCreate(data).then(res => {
					let { data, code } = res.data;
					if (code === "0") {
						ElMessage({
							type: 'success',
							message: '加入成功',
						})
						//取消所有选中
						this.goodsList.map(item => {
							item.active = false;
							item.num = 1;
						});
						//清空选中的项
						this.$refs.table.$refs.lidaTable.clearSelection();
						//刷新购物车列表
						this.getCartList(type);
					}
				})
			} else {
				ElMessage({
					message: '请选择商品',
					type: 'warning',
				})
			}
		},

		showInquiry() {
			if (this.cartGoods.length) {
				this.inquiryShow = true;
			} else {
				ElMessage({
					message: '请添加需要生成询价单的商品',
					type: 'warning',
				})
			}
		},

		showQutoe() {
			if (this.quoteGoodsList.length) {
				this.qutoeShow = true;
			} else {
				ElMessage({
					message: '请添加需要生成报价单的商品',
					type: 'warning',
				})
			}
		},

		handleChange() { //数量改变的事件
			//手动渲染 出发计算价格方法
			this.goodsList = [...this.goodsList];
		},

		deleteCartGoods(row) { //删除购物车里面的商品
			this.deleteSelection(row.id).then(res => {
				let { data, code } = res.data;
				if (code === "0") { //操作成功
					this.cartGoods = this.cartGoods.filter(v => v.id !== row.id);
					this.quoteGoodsList = this.quoteGoodsList.filter(v => v.id !== row.id)
				}
			})
		},

		getCartList(type) { //获取购物车列表
			this.selectionList({ type }).then(res => {
				let { code, data } = res.data;
				if (code === "0") {
					data.map(item => {
						item.src = imgUrl(item);
						item.num = item.skuQty;
					});
					if (type === 'INQUIRY') {
						this.cartGoods = data || [];
					} else {
						this.quoteGoodsList = data || [];
					}
				} else {
					type === 'INQUIRY' ? this.cartGoods = [] : this.quoteGoodsList = []
				}
			})
		},
		cartSuccess(id) { //操作成功刷新购物车数量
			this.shareLinkShow = true;
			this.inquiryId = id;
			this.getCartList('INQUIRY');
		},
	},
	created() {
		this.instance = createLidaButton("加入购物车", this.addCart, Vue);  //创建加入购物车按钮
		this.quoteBtnInstance = createLidaButton("加入报价单", this.addQuoteList, Vue);  //创建加入购物车按钮
		this.getCartList();  //获取购物车列表
	}
}