<template>
	<el-dialog 
		v-model="show" 
		:show-close="false" 
		width="1200px" 
		top="10vh" 
		@open="open"
		:destroy-on-close="true"
		@close="closeDialog">
		<div class="i">
			<div class="i-title">
				<div class="i-title-left">
					<el-button @click="createOrder" type="primary" size="mini">生成询价单</el-button>
				</div>
				<div class="i-title-right" @click="closeDialog">
					<p class="iconfont icon-shang"></p>
					<span>收起选型单</span>
				</div>
			</div>
			<el-tabs v-model="activeNav">
				<el-tab-pane :label="item.title" :name="item.type" v-for="(item,index) in navs" :key="index">
				</el-tab-pane>
			</el-tabs>
			<div class="i-content">
				<!-- 商品信息 -->
				<div v-show="activeNav === '1'">
					<inquiryMainForm ref="mainForm"></inquiryMainForm>
					<lida-table
						:pagination="false" 
						:cutHeight="getHeight" 
						:data="data"
						border
						
						ref="table">
							<lida-table-column label="产品信息" fixed>
								<template #default="scope">
									<div class="g-info">
										<div class="g-icon">
											<img :src="scope.row.src"/>
										</div>
										<div class="g-right">
											<div class="g-r-title">
												{{scope.row.productName}}
												<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
											</div>
											<div class="g-r-data">
												{{scope.row.barCode || '无'}}
												<span>|</span>
												{{scope.row.seriesName || '无'}}
												<span>|</span>
												{{scope.row.skuNumber || '无'}}
											</div>
										</div>
									</div>
								</template>
							</lida-table-column>
							<lida-table-column label="订货号" prop="articleNo" />
							<lida-table-column label="面价" prop="guidePrice" />
							<lida-table-column label="数量" prop="num" />
							<lida-table-column label="操作" fixed="right">
								<template #default="scope">
									<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
								</template>
							</lida-table-column>
					</lida-table>
				</div>
				<div class="iu"
					v-show="activeNav === '2'"
				>
					<inquiry-form 
						ref="form"
						:cities="cities"
						@success="inquirySuccess" />
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import inquiryForm from "./inquiryForm.vue"  //需要提交的form表单
	import inquiryMainForm from './inquiryMainForm.vue' //基本的表单信息
	export default {
		data() {
			return {
				navs:[
					{title: "产品信息",type: "1"},
					{title: "询价信息",type: "2"},
				],
				activeNav: "1", //当前选中的信息
				shareLinkShow:false, //生成链接弹框
				cities:[],
			}
		},
		props: {
			modelValue: Boolean,
			data:Array,
		},
		components:{
			inquiryForm,
			inquiryMainForm
		},
		methods: {
			closeDialog() { //关闭弹框
				this.$emit('update:modelValue', false);
			},
			deleteRow(row){ //删除row的事件
				this.$emit("delete",row);
			},
			cancel(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			createOrder(){
				this.$refs['mainForm'].confirm(info => {
					this.cities = this.$refs['mainForm'].cities;
					this.$nextTick(() => {
						this.$refs['form'].createOrder(info);
					})
				})
			},
			inquirySuccess(id){
				this.$emit('success',id);
				this.cancel();
			},
			open(){
				this.activeNav = '1';
			}
		},
		computed:{
			getHeight(){
				return document.body.clientHeight / 10;
			},
		},
		watch:{
			modelValue(v) {
				this.show = v;
			},
		},
	}
</script>

<style scoped lang="less">
	.i {
		font-size: 14px;
		padding: 10px 20px 20px 20px;
		.i-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0;
			.i-title-right {
				color: #999999;
				display: flex;
				align-items: center;
				cursor: pointer;
				p {
					font-size: 14px;
					margin-right: 4px;
				}
			}
		}
		.i-content{
			height: 70vh;
			.g-info{
				display: flex;
				align-items: center;
				justify-content: space-between;
				.g-icon{
					img{
						width: 60px;
						height: 60px;
					}
				}
				.g-right{
					flex: 1;
					box-sizing: border-box;
					padding-left: 20px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.g-r-title{
						font-size: 14px;
						display: flex;
						.label{
							max-width: 100px;
							background: @dh-color;
							border-radius: 0 10px 10px 10px;
							color: #FFFFFF;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 12px;
							padding: 0 8px;
							margin-left: 10px;
							height: 24px;
							box-sizing: border-box;
						}
					}
					.g-r-data{
						color: #666666;
						font-size: 12px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						span{
							margin: 0 10px;
						}
					}
				}
			}
		}
		.iu{
			width: 100%;
			height: 100%;
		}
	}
</style>
