<template>
  <div class="container">
    <el-button type="primary" size="mini" @click="toAddInquiryPage">快速发起询价</el-button>
  </div>
</template>

<script>
/**
 *   @Author : 王雨波
 *   @Date : 2022/1/21
 *   @Version : 1.0.0
 *   @Last Modified by : 王雨波
 *   @Last Modified time : 2022/1/21
 *   @desc 快速发起询价 按钮
 **/

export default {
  name: "ToAddInquiryPage",
  methods:{
    toAddInquiryPage(){
      this.$router.push({
        path:'/purchase/addInquiryPage',
      })
    }
  }
}
</script>


<style scoped lang="less">

  .container{
    padding: 0 10px;
  }

</style>
